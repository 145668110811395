import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <img role="presentation" width="960" src="https://user-images.githubusercontent.com/2313998/226466889-81266a65-6099-457f-9770-99e4c3a0d712.png" />
    <h2>{`Usage`}</h2>
    <p>{`Use an icon button when the purpose of your button may be easily understood using an icon in place of a text label.`}</p>
    <h3>{`Best practices`}</h3>
    <ul>
      <li parentName="ul">{`Verify that the button's purpose will still be understood using an icon without a text label. Consider your audience and the context where the button is being rendered.`}</li>
      <li parentName="ul">{`Use a tooltip with a text label for the button so users can hover or focus the button to read a button label that makes it clear what the button does.`}</li>
    </ul>
    <h2>{`Options`}</h2>
    <p>{`Icon buttons have the same `}<a parentName="p" {...{
        "href": "/components/button#sizing-and-spacing"
      }}>{`size`}</a>{` and `}<a parentName="p" {...{
        "href": "/components/button#color"
      }}>{`variant`}</a>{` options as a button with a text label.`}</p>
    <h2>{`Accessibility and usability expectations`}</h2>
    <p>{`Even though the label is visually hidden, a text label needs to be defined so a screen reader can read that label to the user. A graphical `}<inlineCode parentName="p">{`IconButton`}</inlineCode>{` must:`}</p>
    <ol>
      <li parentName="ol">{`Have an accessible name (acting as a text alternative for the Octicon) that describes the action that will be performed when the button is activated.`}</li>
      <li parentName="ol">{`Be visually recognisable as representing the action. For example, a magnifying glass icon could be used to represent a search button, with a text alternative of "Search". This allows voice control software users to activate the button by saying "click search", whereas an icon that wasn't recognisable as representing the text alternative would leave the user guessing at the button's accessible name, and other users guessing at its purpose.`}</li>
    </ol>
    <h2>{`Built-in accessibility features`}</h2>
    <p>{`By default, this component is rendered as a regular `}<inlineCode parentName="p">{`<button>`}</inlineCode>{` with a nested `}<inlineCode parentName="p">{`<svg>`}</inlineCode>{` for the Octicon. The `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{` property is used as the button's accessible name.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<button … aria-label="…">
  <svg aria-hidden="true" …> … </svg>
</button>
`}</code></pre>
    <p>{`When the `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` property is set, the component is rendered as a standard `}<inlineCode parentName="p">{`<button disabled> … </button>`}</inlineCode>{` disabled button.`}</p>
    <p>{`When the `}<inlineCode parentName="p">{`inactive`}</inlineCode>{` property is set, the component is rendered as `}<inlineCode parentName="p">{`<button aria-disabled="true"> … </button>`}</inlineCode>{` – the control itself is visually styled to appear disabled. However, the control is still keyboard-focusable, and can still be activated.`}</p>
    <p>{`The selectable variant/scheme colors meet minimum color contrast requirements.`}</p>
    <p>{`The size variants all meet the minimum target size requirement.`}</p>
    <p><inlineCode parentName="p">{`IconButton`}</inlineCode>{` automatically has a visible tooltip that appears when the button is hovered and when it receives keyboard focus. The tooltip visually replicates the accessible name of the button. The tooltip can be hovered when using the mouse, and can be dismissed using `}<inlineCode parentName="p">{`Esc`}</inlineCode>{` without needing to move the mouse or focus away from the button.`}</p>
    <h3>{`Implementation requirements`}</h3>
    <h4>{`Accessible name`}</h4>
    <p>{`You must provide a sufficiently descriptive `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{` for the component.`}</p>
    <h4>{`Target size`}</h4>
    <p>{`The button must have a minimum target size of 24×24 CSS pixels. This is to ensure that the button is large enough to be easily activated by users with motor impairments.`}</p>
    <h4>{`Convey meaning with more than color`}</h4>
    <p><inlineCode parentName="p">{`IconButton`}</inlineCode>{` has different schemes/variants (such as `}<inlineCode parentName="p">{`default`}</inlineCode>{`, `}<inlineCode parentName="p">{`danger`}</inlineCode>{`, `}<inlineCode parentName="p">{`invisible`}</inlineCode>{`), which result in different colors for the icon, background, and borders. These differences are purely visual, so they may be difficult to distinguish for users with impaired color perception. They also won't be perceived by screen reader users. For this reason, you can't rely on the scheme/variant alone to give meaning to your buttons. `}</p>
    <p>{`Make sure to differentiate icon buttons through the use of sufficiently different Octicons, rather than their visual presentation.`}</p>
    <h3>{`How to test the component`}</h3>
    <h4>{`Integration tests`}</h4>
    <ul>
      <li parentName="ul">{`The button has a sufficiently descriptive accessible name`}</li>
      <li parentName="ul">{`The button uses an icon with sufficiently clear meaning that conveys its action`}</li>
      <li parentName="ul">{`If the button uses a schema/variant for a particular presentation, the button does not solely rely on this visual aspect to convey its purpose, or to differentiate it from other buttons`}</li>
    </ul>
    <h4>{`Component tests`}</h4>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` added to the component is output as the button's `}<inlineCode parentName="li">{`aria-label`}</inlineCode></li>
      <li parentName="ul">{`Unless the button is `}<inlineCode parentName="li">{`disabled`}</inlineCode>{`, it can be focused and activated using the keyboard`}</li>
      <li parentName="ul">{`If the button is set to be `}<inlineCode parentName="li">{`inactive`}</inlineCode>{`, it still receives keyboard focus, but it conveys its inactive/disabled state to screen readers with the `}<inlineCode parentName="li">{`aria-disabled="true"`}</inlineCode>{` attribute`}</li>
      <li parentName="ul">{`The button has a minimum target size of 24×24 CSS pixels, regardless of content`}</li>
      <li parentName="ul">{`The tooltip for the button can be hovered with the mouse, and the tooltip can be dismissed using `}<inlineCode parentName="li">{`Esc`}</inlineCode>{` without needing to move the mouse or focus away from the button`}</li>
    </ul>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="IconButton" mdxType="AccessibilityLink" />
    <h2>{`Related links`}</h2>
    {/* TODO: link to Tooltip once it's merged */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/ui-patterns/button-usage"
        }}>{`Button usage`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/button"
        }}>{`Button`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      